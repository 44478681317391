<template>
  <div class="animated fadeIn">
    <b-card no-body id="new47">
      <div slot="header">
        <i class="fa fa-font-awesome"></i> 50 New Icons in 4.7
      </div>
      <b-card-body>
        <b-row class="text-center">
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-address-book fa-lg mt-4"></i><br/>address-book
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-address-book-o fa-lg mt-4"></i><br/>address-book-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-address-card fa-lg mt-4"></i><br/>address-card
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-address-card-o fa-lg mt-4"></i><br/>address-card-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bandcamp fa-lg mt-4"></i><br/>bandcamp
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bath fa-lg mt-4"></i><br/>bath
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bathtub fa-lg mt-4"></i><br/>bathtub <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-drivers-license fa-lg mt-4"></i><br/>drivers-license <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-drivers-license-o fa-lg mt-4"></i><br/>drivers-license-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-eercast fa-lg mt-4"></i><br/>eercast
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-envelope-open fa-lg mt-4"></i><br/>envelope-open
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-envelope-open-o fa-lg mt-4"></i><br/>envelope-open-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-etsy fa-lg mt-4"></i><br/>etsy
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-free-code-camp fa-lg mt-4"></i><br/>free-code-camp
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-grav fa-lg mt-4"></i><br/>grav
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-handshake-o fa-lg mt-4"></i><br/>handshake-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-id-badge fa-lg mt-4"></i><br/>id-badge
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-id-card fa-lg mt-4"></i><br/>id-card
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-id-card-o fa-lg mt-4"></i><br/>id-card-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-imdb fa-lg mt-4"></i><br/>imdb
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-linode fa-lg mt-4"></i><br/>linode
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-meetup fa-lg mt-4"></i><br/>meetup
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-microchip fa-lg mt-4"></i><br/>microchip
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-podcast fa-lg mt-4"></i><br/>podcast
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-quora fa-lg mt-4"></i><br/>quora
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ravelry fa-lg mt-4"></i><br/>ravelry
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-s15 fa-lg mt-4"></i><br/>s15 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-shower fa-lg mt-4"></i><br/>shower
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-snowflake-o fa-lg mt-4"></i><br/>snowflake-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-superpowers fa-lg mt-4"></i><br/>superpowers
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-telegram fa-lg mt-4"></i><br/>telegram
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer fa-lg mt-4"></i><br/>thermometer <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer-0 fa-lg mt-4"></i><br/>thermometer-0 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer-1 fa-lg mt-4"></i><br/>thermometer-1 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer-2 fa-lg mt-4"></i><br/>thermometer-2 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer-3 fa-lg mt-4"></i><br/>thermometer-3 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer-4 fa-lg mt-4"></i><br/>thermometer-4 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer-empty fa-lg mt-4"></i><br/>thermometer-empty
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer-full fa-lg mt-4"></i><br/>thermometer-full
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer-half fa-lg mt-4"></i><br/>thermometer-half
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer-quarter fa-lg mt-4"></i><br/>thermometer-quarter
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thermometer-three-quarters fa-lg mt-4"></i><br/>thermometer-three-quarters
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-times-rectangle fa-lg mt-4"></i><br/>times-rectangle <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-times-rectangle-o fa-lg mt-4"></i><br/>times-rectangle-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-user-circle fa-lg mt-4"></i><br/>user-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-user-circle-o fa-lg mt-4"></i><br/>user-circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-user-o fa-lg mt-4"></i><br/>user-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-vcard fa-lg mt-4"></i><br/>vcard <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-vcard-o fa-lg mt-4"></i><br/>vcard-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-window-close fa-lg mt-4"></i><br/>window-close
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-window-close-o fa-lg mt-4"></i><br/>window-close-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-window-maximize fa-lg mt-4"></i><br/>window-maximize
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-window-minimize fa-lg mt-4"></i><br/>window-minimize
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-window-restore fa-lg mt-4"></i><br/>window-restore
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-wpexplorer fa-lg mt-4"></i><br/>wpexplorer
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body id="new">
      <div slot="header">20 New Icons in 4.5</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bluetooth fa-lg mt-4"></i><br>bluetooth
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bluetooth-b fa-lg mt-4"></i><br>bluetooth-b
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-codiepie fa-lg mt-4"></i><br>codiepie
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-credit-card-alt fa-lg mt-4"></i><br>credit-card-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-edge fa-lg mt-4"></i><br>edge
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-fort-awesome fa-lg mt-4"></i><br>fort-awesome
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hashtag fa-lg mt-4"></i><br>hashtag
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mixcloud fa-lg mt-4"></i><br>mixcloud
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-modx fa-lg mt-4"></i><br>modx
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pause-circle fa-lg mt-4"></i><br>pause-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pause-circle-o fa-lg mt-4"></i><br>pause-circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-percent fa-lg mt-4"></i><br>percent
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-product-hunt fa-lg mt-4"></i><br>product-hunt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-reddit-alien fa-lg mt-4"></i><br>reddit-alien
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-scribd fa-lg mt-4"></i><br>scribd
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-shopping-bag fa-lg mt-4"></i><br>shopping-bag
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-shopping-basket fa-lg mt-4"></i><br>shopping-basket
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-stop-circle fa-lg mt-4"></i><br>stop-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-stop-circle-o fa-lg mt-4"></i><br>stop-circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-usb fa-lg mt-4"></i><br>usb
          </b-col>

        </b-row>

      </b-card-body>
    </b-card>

    <b-card no-body id="web-application">
      <div slot="header">Web Application Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-adjust fa-lg mt-4"></i><br>adjust
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-anchor fa-lg mt-4"></i><br>anchor
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-archive fa-lg mt-4"></i><br>archive
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-area-chart fa-lg mt-4"></i><br>area-chart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrows fa-lg mt-4"></i><br>arrows
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrows-h fa-lg mt-4"></i><br>arrows-h
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrows-v fa-lg mt-4"></i><br>arrows-v
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-asterisk fa-lg mt-4"></i><br>asterisk
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-at fa-lg mt-4"></i><br>at
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-automobile fa-lg mt-4"></i><br>automobile <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-balance-scale fa-lg mt-4"></i><br>balance-scale
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ban fa-lg mt-4"></i><br>ban
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bank fa-lg mt-4"></i><br>bank <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bar-chart fa-lg mt-4"></i><br>bar-chart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bar-chart-o fa-lg mt-4"></i><br>bar-chart-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-barcode fa-lg mt-4"></i><br>barcode
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bars fa-lg mt-4"></i><br>bars
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-battery-0 fa-lg mt-4"></i><br>battery-0 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-battery-1 fa-lg mt-4"></i><br>battery-1 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-battery-2 fa-lg mt-4"></i><br>battery-2 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-battery-3 fa-lg mt-4"></i><br>battery-3 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-battery-4 fa-lg mt-4"></i><br>battery-4 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-battery-empty fa-lg mt-4"></i><br>battery-empty
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-battery-full fa-lg mt-4"></i><br>battery-full
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-battery-half fa-lg mt-4"></i><br>battery-half
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-battery-quarter fa-lg mt-4"></i><br>battery-quarter
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-battery-three-quarters fa-lg mt-4"></i><br>battery-three-quarters
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bed fa-lg mt-4"></i><br>bed
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-beer fa-lg mt-4"></i><br>beer
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bell fa-lg mt-4"></i><br>bell
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bell-o fa-lg mt-4"></i><br>bell-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bell-slash fa-lg mt-4"></i><br>bell-slash
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bell-slash-o fa-lg mt-4"></i><br>bell-slash-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bicycle fa-lg mt-4"></i><br>bicycle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-binoculars fa-lg mt-4"></i><br>binoculars
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-birthday-cake fa-lg mt-4"></i><br>birthday-cake
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bluetooth fa-lg mt-4"></i><br>bluetooth
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bluetooth-b fa-lg mt-4"></i><br>bluetooth-b
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bolt fa-lg mt-4"></i><br>bolt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bomb fa-lg mt-4"></i><br>bomb
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-book fa-lg mt-4"></i><br>book
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bookmark fa-lg mt-4"></i><br>bookmark
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bookmark-o fa-lg mt-4"></i><br>bookmark-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-briefcase fa-lg mt-4"></i><br>briefcase
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bug fa-lg mt-4"></i><br>bug
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-building fa-lg mt-4"></i><br>building
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-building-o fa-lg mt-4"></i><br>building-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bullhorn fa-lg mt-4"></i><br>bullhorn
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bullseye fa-lg mt-4"></i><br>bullseye
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bus fa-lg mt-4"></i><br>bus
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cab fa-lg mt-4"></i><br>cab <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-calculator fa-lg mt-4"></i><br>calculator
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-calendar fa-lg mt-4"></i><br>calendar
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-calendar-check-o fa-lg mt-4"></i><br>calendar-check-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-calendar-minus-o fa-lg mt-4"></i><br>calendar-minus-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-calendar-o fa-lg mt-4"></i><br>calendar-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-calendar-plus-o fa-lg mt-4"></i><br>calendar-plus-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-calendar-times-o fa-lg mt-4"></i><br>calendar-times-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-camera fa-lg mt-4"></i><br>camera
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-camera-retro fa-lg mt-4"></i><br>camera-retro
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-car fa-lg mt-4"></i><br>car
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-square-o-down fa-lg mt-4"></i><br>caret-square-o-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-square-o-left fa-lg mt-4"></i><br>caret-square-o-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-square-o-right fa-lg mt-4"></i><br>caret-square-o-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-square-o-up fa-lg mt-4"></i><br>caret-square-o-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cart-arrow-down fa-lg mt-4"></i><br>cart-arrow-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cart-plus fa-lg mt-4"></i><br>cart-plus
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc fa-lg mt-4"></i><br>cc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-certificate fa-lg mt-4"></i><br>certificate
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-check fa-lg mt-4"></i><br>check
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-check-circle fa-lg mt-4"></i><br>check-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-check-circle-o fa-lg mt-4"></i><br>check-circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-check-square fa-lg mt-4"></i><br>check-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-check-square-o fa-lg mt-4"></i><br>check-square-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-child fa-lg mt-4"></i><br>child
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-circle fa-lg mt-4"></i><br>circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-circle-o fa-lg mt-4"></i><br>circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-circle-o-notch fa-lg mt-4"></i><br>circle-o-notch
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-circle-thin fa-lg mt-4"></i><br>circle-thin
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-clock-o fa-lg mt-4"></i><br>clock-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-clone fa-lg mt-4"></i><br>clone
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-close fa-lg mt-4"></i><br>close <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cloud fa-lg mt-4"></i><br>cloud
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cloud-download fa-lg mt-4"></i><br>cloud-download
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cloud-upload fa-lg mt-4"></i><br>cloud-upload
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-code fa-lg mt-4"></i><br>code
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-code-fork fa-lg mt-4"></i><br>code-fork
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-coffee fa-lg mt-4"></i><br>coffee
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cog fa-lg mt-4"></i><br>cog
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cogs fa-lg mt-4"></i><br>cogs
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-comment fa-lg mt-4"></i><br>comment
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-comment-o fa-lg mt-4"></i><br>comment-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-commenting fa-lg mt-4"></i><br>commenting
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-commenting-o fa-lg mt-4"></i><br>commenting-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-comments fa-lg mt-4"></i><br>comments
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-comments-o fa-lg mt-4"></i><br>comments-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-compass fa-lg mt-4"></i><br>compass
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-copyright fa-lg mt-4"></i><br>copyright
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-creative-commons fa-lg mt-4"></i><br>creative-commons
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-credit-card fa-lg mt-4"></i><br>credit-card
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-credit-card-alt fa-lg mt-4"></i><br>credit-card-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-crop fa-lg mt-4"></i><br>crop
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-crosshairs fa-lg mt-4"></i><br>crosshairs
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cube fa-lg mt-4"></i><br>cube
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cubes fa-lg mt-4"></i><br>cubes
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cutlery fa-lg mt-4"></i><br>cutlery
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-dashboard fa-lg mt-4"></i><br>dashboard <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-database fa-lg mt-4"></i><br>database
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-desktop fa-lg mt-4"></i><br>desktop
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-diamond fa-lg mt-4"></i><br>diamond
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-dot-circle-o fa-lg mt-4"></i><br>dot-circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-download fa-lg mt-4"></i><br>download
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-edit fa-lg mt-4"></i><br>edit <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ellipsis-h fa-lg mt-4"></i><br>ellipsis-h
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ellipsis-v fa-lg mt-4"></i><br>ellipsis-v
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-envelope fa-lg mt-4"></i><br>envelope
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-envelope-o fa-lg mt-4"></i><br>envelope-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-envelope-square fa-lg mt-4"></i><br>envelope-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-eraser fa-lg mt-4"></i><br>eraser
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-exchange fa-lg mt-4"></i><br>exchange
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-exclamation fa-lg mt-4"></i><br>exclamation
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-exclamation-circle fa-lg mt-4"></i><br>exclamation-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-exclamation-triangle fa-lg mt-4"></i><br>exclamation-triangle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-external-link fa-lg mt-4"></i><br>external-link
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-external-link-square fa-lg mt-4"></i><br>external-link-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-eye fa-lg mt-4"></i><br>eye
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-eye-slash fa-lg mt-4"></i><br>eye-slash
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-eyedropper fa-lg mt-4"></i><br>eyedropper
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-fax fa-lg mt-4"></i><br>fax
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-feed fa-lg mt-4"></i><br>feed <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-female fa-lg mt-4"></i><br>female
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-fighter-jet fa-lg mt-4"></i><br>fighter-jet
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-archive-o fa-lg mt-4"></i><br>file-archive-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-audio-o fa-lg mt-4"></i><br>file-audio-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-code-o fa-lg mt-4"></i><br>file-code-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-excel-o fa-lg mt-4"></i><br>file-excel-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-image-o fa-lg mt-4"></i><br>file-image-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-movie-o fa-lg mt-4"></i><br>file-movie-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-pdf-o fa-lg mt-4"></i><br>file-pdf-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-photo-o fa-lg mt-4"></i><br>file-photo-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-picture-o fa-lg mt-4"></i><br>file-picture-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-powerpoint-o fa-lg mt-4"></i><br>file-powerpoint-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-sound-o fa-lg mt-4"></i><br>file-sound-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-video-o fa-lg mt-4"></i><br>file-video-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-word-o fa-lg mt-4"></i><br>file-word-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-zip-o fa-lg mt-4"></i><br>file-zip-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-film fa-lg mt-4"></i><br>film
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-filter fa-lg mt-4"></i><br>filter
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-fire fa-lg mt-4"></i><br>fire
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-fire-extinguisher fa-lg mt-4"></i><br>fire-extinguisher
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-flag fa-lg mt-4"></i><br>flag
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-flag-checkered fa-lg mt-4"></i><br>flag-checkered
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-flag-o fa-lg mt-4"></i><br>flag-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-flash fa-lg mt-4"></i><br>flash <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-flask fa-lg mt-4"></i><br>flask
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-folder fa-lg mt-4"></i><br>folder
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-folder-o fa-lg mt-4"></i><br>folder-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-folder-open fa-lg mt-4"></i><br>folder-open
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-folder-open-o fa-lg mt-4"></i><br>folder-open-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-frown-o fa-lg mt-4"></i><br>frown-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-futbol-o fa-lg mt-4"></i><br>futbol-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gamepad fa-lg mt-4"></i><br>gamepad
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gavel fa-lg mt-4"></i><br>gavel
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gear fa-lg mt-4"></i><br>gear <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gears fa-lg mt-4"></i><br>gears <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gift fa-lg mt-4"></i><br>gift
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-glass fa-lg mt-4"></i><br>glass
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-globe fa-lg mt-4"></i><br>globe
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-graduation-cap fa-lg mt-4"></i><br>graduation-cap
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-group fa-lg mt-4"></i><br>group <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-grab-o fa-lg mt-4"></i><br>hand-grab-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-lizard-o fa-lg mt-4"></i><br>hand-lizard-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-paper-o fa-lg mt-4"></i><br>hand-paper-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-peace-o fa-lg mt-4"></i><br>hand-peace-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-pointer-o fa-lg mt-4"></i><br>hand-pointer-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-rock-o fa-lg mt-4"></i><br>hand-rock-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-scissors-o fa-lg mt-4"></i><br>hand-scissors-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-spock-o fa-lg mt-4"></i><br>hand-spock-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-stop-o fa-lg mt-4"></i><br>hand-stop-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hashtag fa-lg mt-4"></i><br>hashtag
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hdd-o fa-lg mt-4"></i><br>hdd-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-headphones fa-lg mt-4"></i><br>headphones
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-heart fa-lg mt-4"></i><br>heart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-heart-o fa-lg mt-4"></i><br>heart-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-heartbeat fa-lg mt-4"></i><br>heartbeat
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-history fa-lg mt-4"></i><br>history
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-home fa-lg mt-4"></i><br>home
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hotel fa-lg mt-4"></i><br>hotel <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hourglass fa-lg mt-4"></i><br>hourglass
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hourglass-1 fa-lg mt-4"></i><br>hourglass-1 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hourglass-2 fa-lg mt-4"></i><br>hourglass-2 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hourglass-3 fa-lg mt-4"></i><br>hourglass-3 <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hourglass-end fa-lg mt-4"></i><br>hourglass-end
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hourglass-half fa-lg mt-4"></i><br>hourglass-half
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hourglass-o fa-lg mt-4"></i><br>hourglass-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hourglass-start fa-lg mt-4"></i><br>hourglass-start
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-i-cursor fa-lg mt-4"></i><br>i-cursor
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-image fa-lg mt-4"></i><br>image <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-inbox fa-lg mt-4"></i><br>inbox
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-industry fa-lg mt-4"></i><br>industry
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-info fa-lg mt-4"></i><br>info
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-info-circle fa-lg mt-4"></i><br>info-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-institution fa-lg mt-4"></i><br>institution <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-key fa-lg mt-4"></i><br>key
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-keyboard-o fa-lg mt-4"></i><br>keyboard-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-language fa-lg mt-4"></i><br>language
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-laptop fa-lg mt-4"></i><br>laptop
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-leaf fa-lg mt-4"></i><br>leaf
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-legal fa-lg mt-4"></i><br>legal <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-lemon-o fa-lg mt-4"></i><br>lemon-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-level-down fa-lg mt-4"></i><br>level-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-level-up fa-lg mt-4"></i><br>level-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-life-bouy fa-lg mt-4"></i><br>life-bouy <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-life-buoy fa-lg mt-4"></i><br>life-buoy <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-life-ring fa-lg mt-4"></i><br>life-ring
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-life-saver fa-lg mt-4"></i><br>life-saver <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-lightbulb-o fa-lg mt-4"></i><br>lightbulb-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-line-chart fa-lg mt-4"></i><br>line-chart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-location-arrow fa-lg mt-4"></i><br>location-arrow
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-lock fa-lg mt-4"></i><br>lock
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-magic fa-lg mt-4"></i><br>magic
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-magnet fa-lg mt-4"></i><br>magnet
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mail-forward fa-lg mt-4"></i><br>mail-forward <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mail-reply fa-lg mt-4"></i><br>mail-reply <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mail-reply-all fa-lg mt-4"></i><br>mail-reply-all <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-male fa-lg mt-4"></i><br>male
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-map fa-lg mt-4"></i><br>map
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-map-marker fa-lg mt-4"></i><br>map-marker
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-map-o fa-lg mt-4"></i><br>map-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-map-pin fa-lg mt-4"></i><br>map-pin
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-map-signs fa-lg mt-4"></i><br>map-signs
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-meh-o fa-lg mt-4"></i><br>meh-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-microphone fa-lg mt-4"></i><br>microphone
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-microphone-slash fa-lg mt-4"></i><br>microphone-slash
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-minus fa-lg mt-4"></i><br>minus
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-minus-circle fa-lg mt-4"></i><br>minus-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-minus-square fa-lg mt-4"></i><br>minus-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-minus-square-o fa-lg mt-4"></i><br>minus-square-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mobile fa-lg mt-4"></i><br>mobile
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mobile-phone fa-lg mt-4"></i><br>mobile-phone <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-money fa-lg mt-4"></i><br>money
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-moon-o fa-lg mt-4"></i><br>moon-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mortar-board fa-lg mt-4"></i><br>mortar-board <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-motorcycle fa-lg mt-4"></i><br>motorcycle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mouse-pointer fa-lg mt-4"></i><br>mouse-pointer
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-music fa-lg mt-4"></i><br>music
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-navicon fa-lg mt-4"></i><br>navicon <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-newspaper-o fa-lg mt-4"></i><br>newspaper-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-object-group fa-lg mt-4"></i><br>object-group
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-object-ungroup fa-lg mt-4"></i><br>object-ungroup
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-paint-brush fa-lg mt-4"></i><br>paint-brush
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-paper-plane fa-lg mt-4"></i><br>paper-plane
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-paper-plane-o fa-lg mt-4"></i><br>paper-plane-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-paw fa-lg mt-4"></i><br>paw
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pencil fa-lg mt-4"></i><br>pencil
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pencil-square fa-lg mt-4"></i><br>pencil-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pencil-square-o fa-lg mt-4"></i><br>pencil-square-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-percent fa-lg mt-4"></i><br>percent
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-phone fa-lg mt-4"></i><br>phone
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-phone-square fa-lg mt-4"></i><br>phone-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-photo fa-lg mt-4"></i><br>photo <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-picture-o fa-lg mt-4"></i><br>picture-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pie-chart fa-lg mt-4"></i><br>pie-chart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-plane fa-lg mt-4"></i><br>plane
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-plug fa-lg mt-4"></i><br>plug
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-plus fa-lg mt-4"></i><br>plus
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-plus-circle fa-lg mt-4"></i><br>plus-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-plus-square fa-lg mt-4"></i><br>plus-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-plus-square-o fa-lg mt-4"></i><br>plus-square-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-power-off fa-lg mt-4"></i><br>power-off
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-print fa-lg mt-4"></i><br>print
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-puzzle-piece fa-lg mt-4"></i><br>puzzle-piece
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-qrcode fa-lg mt-4"></i><br>qrcode
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-question fa-lg mt-4"></i><br>question
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-question-circle fa-lg mt-4"></i><br>question-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-quote-left fa-lg mt-4"></i><br>quote-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-quote-right fa-lg mt-4"></i><br>quote-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-random fa-lg mt-4"></i><br>random
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-recycle fa-lg mt-4"></i><br>recycle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-refresh fa-lg mt-4"></i><br>refresh
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-registered fa-lg mt-4"></i><br>registered
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-remove fa-lg mt-4"></i><br>remove <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-reorder fa-lg mt-4"></i><br>reorder <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-reply fa-lg mt-4"></i><br>reply
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-reply-all fa-lg mt-4"></i><br>reply-all
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-retweet fa-lg mt-4"></i><br>retweet
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-road fa-lg mt-4"></i><br>road
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rocket fa-lg mt-4"></i><br>rocket
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rss fa-lg mt-4"></i><br>rss
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rss-square fa-lg mt-4"></i><br>rss-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-search fa-lg mt-4"></i><br>search
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-search-minus fa-lg mt-4"></i><br>search-minus
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-search-plus fa-lg mt-4"></i><br>search-plus
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-send fa-lg mt-4"></i><br>send <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-send-o fa-lg mt-4"></i><br>send-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-server fa-lg mt-4"></i><br>server
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-share fa-lg mt-4"></i><br>share
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-share-alt fa-lg mt-4"></i><br>share-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-share-alt-square fa-lg mt-4"></i><br>share-alt-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-share-square fa-lg mt-4"></i><br>share-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-share-square-o fa-lg mt-4"></i><br>share-square-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-shield fa-lg mt-4"></i><br>shield
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ship fa-lg mt-4"></i><br>ship
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-shopping-bag fa-lg mt-4"></i><br>shopping-bag
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-shopping-basket fa-lg mt-4"></i><br>shopping-basket
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-shopping-cart fa-lg mt-4"></i><br>shopping-cart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sign-in fa-lg mt-4"></i><br>sign-in
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sign-out fa-lg mt-4"></i><br>sign-out
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-signal fa-lg mt-4"></i><br>signal
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sitemap fa-lg mt-4"></i><br>sitemap
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sliders fa-lg mt-4"></i><br>sliders
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-smile-o fa-lg mt-4"></i><br>smile-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-soccer-ball-o fa-lg mt-4"></i><br>soccer-ball-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort fa-lg mt-4"></i><br>sort
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort-alpha-asc fa-lg mt-4"></i><br>sort-alpha-asc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort-alpha-desc fa-lg mt-4"></i><br>sort-alpha-desc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort-amount-asc fa-lg mt-4"></i><br>sort-amount-asc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort-amount-desc fa-lg mt-4"></i><br>sort-amount-desc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort-asc fa-lg mt-4"></i><br>sort-asc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort-desc fa-lg mt-4"></i><br>sort-desc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort-down fa-lg mt-4"></i><br>sort-down <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort-numeric-asc fa-lg mt-4"></i><br>sort-numeric-asc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort-numeric-desc fa-lg mt-4"></i><br>sort-numeric-desc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sort-up fa-lg mt-4"></i><br>sort-up <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-space-shuttle fa-lg mt-4"></i><br>space-shuttle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-spinner fa-lg mt-4"></i><br>spinner
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-spoon fa-lg mt-4"></i><br>spoon
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-square fa-lg mt-4"></i><br>square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-square-o fa-lg mt-4"></i><br>square-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-star fa-lg mt-4"></i><br>star
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-star-half fa-lg mt-4"></i><br>star-half
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-star-half-empty fa-lg mt-4"></i><br>star-half-empty <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-star-half-full fa-lg mt-4"></i><br>star-half-full <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-star-half-o fa-lg mt-4"></i><br>star-half-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-star-o fa-lg mt-4"></i><br>star-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sticky-note fa-lg mt-4"></i><br>sticky-note
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sticky-note-o fa-lg mt-4"></i><br>sticky-note-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-street-view fa-lg mt-4"></i><br>street-view
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-suitcase fa-lg mt-4"></i><br>suitcase
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sun-o fa-lg mt-4"></i><br>sun-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-support fa-lg mt-4"></i><br>support <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tablet fa-lg mt-4"></i><br>tablet
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tachometer fa-lg mt-4"></i><br>tachometer
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tag fa-lg mt-4"></i><br>tag
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tags fa-lg mt-4"></i><br>tags
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tasks fa-lg mt-4"></i><br>tasks
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-taxi fa-lg mt-4"></i><br>taxi
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-television fa-lg mt-4"></i><br>television
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-terminal fa-lg mt-4"></i><br>terminal
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thumb-tack fa-lg mt-4"></i><br>thumb-tack
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thumbs-down fa-lg mt-4"></i><br>thumbs-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thumbs-o-down fa-lg mt-4"></i><br>thumbs-o-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thumbs-o-up fa-lg mt-4"></i><br>thumbs-o-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thumbs-up fa-lg mt-4"></i><br>thumbs-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ticket fa-lg mt-4"></i><br>ticket
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-times fa-lg mt-4"></i><br>times
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-times-circle fa-lg mt-4"></i><br>times-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-times-circle-o fa-lg mt-4"></i><br>times-circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tint fa-lg mt-4"></i><br>tint
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-toggle-down fa-lg mt-4"></i><br>toggle-down <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-toggle-left fa-lg mt-4"></i><br>toggle-left <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-toggle-off fa-lg mt-4"></i><br>toggle-off
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-toggle-on fa-lg mt-4"></i><br>toggle-on
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-toggle-right fa-lg mt-4"></i><br>toggle-right <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-toggle-up fa-lg mt-4"></i><br>toggle-up <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-trademark fa-lg mt-4"></i><br>trademark
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-trash fa-lg mt-4"></i><br>trash
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-trash-o fa-lg mt-4"></i><br>trash-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tree fa-lg mt-4"></i><br>tree
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-trophy fa-lg mt-4"></i><br>trophy
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-truck fa-lg mt-4"></i><br>truck
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tty fa-lg mt-4"></i><br>tty
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tv fa-lg mt-4"></i><br>tv <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-umbrella fa-lg mt-4"></i><br>umbrella
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-university fa-lg mt-4"></i><br>university
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-unlock fa-lg mt-4"></i><br>unlock
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-unlock-alt fa-lg mt-4"></i><br>unlock-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-unsorted fa-lg mt-4"></i><br>unsorted <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-upload fa-lg mt-4"></i><br>upload
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-user fa-lg mt-4"></i><br>user
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-user-plus fa-lg mt-4"></i><br>user-plus
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-user-secret fa-lg mt-4"></i><br>user-secret
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-user-times fa-lg mt-4"></i><br>user-times
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-users fa-lg mt-4"></i><br>users
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-video-camera fa-lg mt-4"></i><br>video-camera
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-volume-down fa-lg mt-4"></i><br>volume-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-volume-off fa-lg mt-4"></i><br>volume-off
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-volume-up fa-lg mt-4"></i><br>volume-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-warning fa-lg mt-4"></i><br>warning <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-wheelchair fa-lg mt-4"></i><br>wheelchair
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-wifi fa-lg mt-4"></i><br>wifi
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-wrench fa-lg mt-4"></i><br>wrench
          </b-col>

        </b-row>
      </b-card-body>

    </b-card>

    <b-card no-body id="hand">
      <div slot="header">Hand Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-grab-o fa-lg mt-4"></i><br>hand-grab-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-lizard-o fa-lg mt-4"></i><br>hand-lizard-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-o-down fa-lg mt-4"></i><br>hand-o-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-o-left fa-lg mt-4"></i><br>hand-o-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-o-right fa-lg mt-4"></i><br>hand-o-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-o-up fa-lg mt-4"></i><br>hand-o-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-paper-o fa-lg mt-4"></i><br>hand-paper-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-peace-o fa-lg mt-4"></i><br>hand-peace-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-pointer-o fa-lg mt-4"></i><br>hand-pointer-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-rock-o fa-lg mt-4"></i><br>hand-rock-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-scissors-o fa-lg mt-4"></i><br>hand-scissors-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-spock-o fa-lg mt-4"></i><br>hand-spock-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-stop-o fa-lg mt-4"></i><br>hand-stop-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thumbs-down fa-lg mt-4"></i><br>thumbs-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thumbs-o-down fa-lg mt-4"></i><br>thumbs-o-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thumbs-o-up fa-lg mt-4"></i><br>thumbs-o-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-thumbs-up fa-lg mt-4"></i><br>thumbs-up
          </b-col>

        </b-row>
      </b-card-body>

    </b-card>

    <b-card no-body id="transportation">
      <div slot="header">Transportation Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ambulance fa-lg mt-4"></i><br>ambulance
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-automobile fa-lg mt-4"></i><br>automobile <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bicycle fa-lg mt-4"></i><br>bicycle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bus fa-lg mt-4"></i><br>bus
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cab fa-lg mt-4"></i><br>cab <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-car fa-lg mt-4"></i><br>car
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-fighter-jet fa-lg mt-4"></i><br>fighter-jet
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-motorcycle fa-lg mt-4"></i><br>motorcycle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-plane fa-lg mt-4"></i><br>plane
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rocket fa-lg mt-4"></i><br>rocket
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ship fa-lg mt-4"></i><br>ship
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-space-shuttle fa-lg mt-4"></i><br>space-shuttle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-subway fa-lg mt-4"></i><br>subway
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-taxi fa-lg mt-4"></i><br>taxi
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-train fa-lg mt-4"></i><br>train
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-truck fa-lg mt-4"></i><br>truck
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-wheelchair fa-lg mt-4"></i><br>wheelchair
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="gender">
      <div slot="header">Gender Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-genderless fa-lg mt-4"></i><br>genderless
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-intersex fa-lg mt-4"></i><br>intersex <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mars fa-lg mt-4"></i><br>mars
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mars-double fa-lg mt-4"></i><br>mars-double
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mars-stroke fa-lg mt-4"></i><br>mars-stroke
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mars-stroke-h fa-lg mt-4"></i><br>mars-stroke-h
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mars-stroke-v fa-lg mt-4"></i><br>mars-stroke-v
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mercury fa-lg mt-4"></i><br>mercury
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-neuter fa-lg mt-4"></i><br>neuter
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-transgender fa-lg mt-4"></i><br>transgender
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-transgender-alt fa-lg mt-4"></i><br>transgender-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-venus fa-lg mt-4"></i><br>venus
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-venus-double fa-lg mt-4"></i><br>venus-double
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-venus-mars fa-lg mt-4"></i><br>venus-mars
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="file-type">
      <div slot="header">File Type Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file fa-lg mt-4"></i><br>file
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-archive-o fa-lg mt-4"></i><br>file-archive-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-audio-o fa-lg mt-4"></i><br>file-audio-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-code-o fa-lg mt-4"></i><br>file-code-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-excel-o fa-lg mt-4"></i><br>file-excel-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-image-o fa-lg mt-4"></i><br>file-image-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-movie-o fa-lg mt-4"></i><br>file-movie-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-o fa-lg mt-4"></i><br>file-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-pdf-o fa-lg mt-4"></i><br>file-pdf-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-photo-o fa-lg mt-4"></i><br>file-photo-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-picture-o fa-lg mt-4"></i><br>file-picture-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-powerpoint-o fa-lg mt-4"></i><br>file-powerpoint-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-sound-o fa-lg mt-4"></i><br>file-sound-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-text fa-lg mt-4"></i><br>file-text
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-text-o fa-lg mt-4"></i><br>file-text-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-video-o fa-lg mt-4"></i><br>file-video-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-word-o fa-lg mt-4"></i><br>file-word-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-zip-o fa-lg mt-4"></i><br>file-zip-o <span class="text-muted">(alias)</span>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="spinner">
      <div slot="header">Spinner Icons</div>
      <b-card-body>
        <div class="alert alert-success">
          <ul class="fa-ul">
            <li>
              <i class="fa fa-info-circle fa-li"></i>
              These icons work great with the <code>fa-spin</code> class.
            </li>
          </ul>
        </div>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-circle-o-notch fa-lg mt-4 fa-spin"></i><br>circle-o-notch
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cog fa-lg mt-4"></i><br>cog
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gear fa-lg mt-4"></i><br>gear <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-refresh fa-lg mt-4"></i><br>refresh
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-spinner fa-lg mt-4"></i><br>spinner
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="form-control">
      <div slot="header">Form Control Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-check-square fa-lg mt-4"></i><br>check-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-check-square-o fa-lg mt-4"></i><br>check-square-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-circle fa-lg mt-4"></i><br>circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-circle-o fa-lg mt-4"></i><br>circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-dot-circle-o fa-lg mt-4"></i><br>dot-circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-minus-square fa-lg mt-4"></i><br>minus-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-minus-square-o fa-lg mt-4"></i><br>minus-square-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-plus-square fa-lg mt-4"></i><br>plus-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-plus-square-o fa-lg mt-4"></i><br>plus-square-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-square fa-lg mt-4"></i><br>square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-square-o fa-lg mt-4"></i><br>square-o
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="payment">
      <div slot="header">Payment Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-amex fa-lg mt-4"></i><br>cc-amex
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-diners-club fa-lg mt-4"></i><br>cc-diners-club
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-discover fa-lg mt-4"></i><br>cc-discover
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-jcb fa-lg mt-4"></i><br>cc-jcb
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-mastercard fa-lg mt-4"></i><br>cc-mastercard
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-paypal fa-lg mt-4"></i><br>cc-paypal
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-stripe fa-lg mt-4"></i><br>cc-stripe
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-visa fa-lg mt-4"></i><br>cc-visa
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-credit-card fa-lg mt-4"></i><br>credit-card
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-credit-card-alt fa-lg mt-4"></i><br>credit-card-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-google-wallet fa-lg mt-4"></i><br>google-wallet
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-paypal fa-lg mt-4"></i><br>paypal
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="chart">
      <div slot="header">Chart Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-area-chart fa-lg mt-4"></i><br>area-chart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bar-chart fa-lg mt-4"></i><br>bar-chart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bar-chart-o fa-lg mt-4"></i><br>bar-chart-o <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-line-chart fa-lg mt-4"></i><br>line-chart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pie-chart fa-lg mt-4"></i><br>pie-chart
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="currency">
      <div slot="header">Currency Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bitcoin fa-lg mt-4"></i><br>bitcoin <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-btc fa-lg mt-4"></i><br>btc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cny fa-lg mt-4"></i><br>cny <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-dollar fa-lg mt-4"></i><br>dollar <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-eur fa-lg mt-4"></i><br>eur
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-euro fa-lg mt-4"></i><br>euro <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gbp fa-lg mt-4"></i><br>gbp
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gg fa-lg mt-4"></i><br>gg
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gg-circle fa-lg mt-4"></i><br>gg-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ils fa-lg mt-4"></i><br>ils
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-inr fa-lg mt-4"></i><br>inr
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-jpy fa-lg mt-4"></i><br>jpy
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-krw fa-lg mt-4"></i><br>krw
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-money fa-lg mt-4"></i><br>money
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rmb fa-lg mt-4"></i><br>rmb <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rouble fa-lg mt-4"></i><br>rouble <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rub fa-lg mt-4"></i><br>rub
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ruble fa-lg mt-4"></i><br>ruble <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rupee fa-lg mt-4"></i><br>rupee <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-shekel fa-lg mt-4"></i><br>shekel <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sheqel fa-lg mt-4"></i><br>sheqel <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-try fa-lg mt-4"></i><br>try
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-turkish-lira fa-lg mt-4"></i><br>turkish-lira <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-usd fa-lg mt-4"></i><br>usd
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-won fa-lg mt-4"></i><br>won <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-yen fa-lg mt-4"></i><br>yen <span class="text-muted">(alias)</span>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="text-editor">
      <div slot="header">Text Editor Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-align-center fa-lg mt-4"></i><br>align-center
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-align-justify fa-lg mt-4"></i><br>align-justify
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-align-left fa-lg mt-4"></i><br>align-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-align-right fa-lg mt-4"></i><br>align-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bold fa-lg mt-4"></i><br>bold
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chain fa-lg mt-4"></i><br>chain <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chain-broken fa-lg mt-4"></i><br>chain-broken
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-clipboard fa-lg mt-4"></i><br>clipboard
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-columns fa-lg mt-4"></i><br>columns
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-copy fa-lg mt-4"></i><br>copy <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cut fa-lg mt-4"></i><br>cut <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-dedent fa-lg mt-4"></i><br>dedent <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-eraser fa-lg mt-4"></i><br>eraser
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file fa-lg mt-4"></i><br>file
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-o fa-lg mt-4"></i><br>file-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-text fa-lg mt-4"></i><br>file-text
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-file-text-o fa-lg mt-4"></i><br>file-text-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-files-o fa-lg mt-4"></i><br>files-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-floppy-o fa-lg mt-4"></i><br>floppy-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-font fa-lg mt-4"></i><br>font
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-header fa-lg mt-4"></i><br>header
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-indent fa-lg mt-4"></i><br>indent
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-italic fa-lg mt-4"></i><br>italic
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-link fa-lg mt-4"></i><br>link
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-list fa-lg mt-4"></i><br>list
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-list-alt fa-lg mt-4"></i><br>list-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-list-ol fa-lg mt-4"></i><br>list-ol
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-list-ul fa-lg mt-4"></i><br>list-ul
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-outdent fa-lg mt-4"></i><br>outdent
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-paperclip fa-lg mt-4"></i><br>paperclip
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-paragraph fa-lg mt-4"></i><br>paragraph
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-paste fa-lg mt-4"></i><br>paste <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-repeat fa-lg mt-4"></i><br>repeat
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rotate-left fa-lg mt-4"></i><br>rotate-left <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rotate-right fa-lg mt-4"></i><br>rotate-right <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-save fa-lg mt-4"></i><br>save <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-scissors fa-lg mt-4"></i><br>scissors
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-strikethrough fa-lg mt-4"></i><br>strikethrough
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-subscript fa-lg mt-4"></i><br>subscript
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-superscript fa-lg mt-4"></i><br>superscript
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-table fa-lg mt-4"></i><br>table
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-text-height fa-lg mt-4"></i><br>text-height
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-text-width fa-lg mt-4"></i><br>text-width
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-th fa-lg mt-4"></i><br>th
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-th-large fa-lg mt-4"></i><br>th-large
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-th-list fa-lg mt-4"></i><br>th-list
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-underline fa-lg mt-4"></i><br>underline
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-undo fa-lg mt-4"></i><br>undo
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-unlink fa-lg mt-4"></i><br>unlink <span class="text-muted">(alias)</span>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="directional">
      <div slot="header">Directional Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-angle-double-down fa-lg mt-4"></i><br>angle-double-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-angle-double-left fa-lg mt-4"></i><br>angle-double-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-angle-double-right fa-lg mt-4"></i><br>angle-double-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-angle-double-up fa-lg mt-4"></i><br>angle-double-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-angle-down fa-lg mt-4"></i><br>angle-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-angle-left fa-lg mt-4"></i><br>angle-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-angle-right fa-lg mt-4"></i><br>angle-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-angle-up fa-lg mt-4"></i><br>angle-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-circle-down fa-lg mt-4"></i><br>arrow-circle-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-circle-left fa-lg mt-4"></i><br>arrow-circle-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-circle-o-down fa-lg mt-4"></i><br>arrow-circle-o-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-circle-o-left fa-lg mt-4"></i><br>arrow-circle-o-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-circle-o-right fa-lg mt-4"></i><br>arrow-circle-o-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-circle-o-up fa-lg mt-4"></i><br>arrow-circle-o-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-circle-right fa-lg mt-4"></i><br>arrow-circle-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-circle-up fa-lg mt-4"></i><br>arrow-circle-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-down fa-lg mt-4"></i><br>arrow-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-left fa-lg mt-4"></i><br>arrow-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-right fa-lg mt-4"></i><br>arrow-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrow-up fa-lg mt-4"></i><br>arrow-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrows fa-lg mt-4"></i><br>arrows
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrows-alt fa-lg mt-4"></i><br>arrows-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrows-h fa-lg mt-4"></i><br>arrows-h
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrows-v fa-lg mt-4"></i><br>arrows-v
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-down fa-lg mt-4"></i><br>caret-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-left fa-lg mt-4"></i><br>caret-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-right fa-lg mt-4"></i><br>caret-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-square-o-down fa-lg mt-4"></i><br>caret-square-o-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-square-o-left fa-lg mt-4"></i><br>caret-square-o-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-square-o-right fa-lg mt-4"></i><br>caret-square-o-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-square-o-up fa-lg mt-4"></i><br>caret-square-o-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-caret-up fa-lg mt-4"></i><br>caret-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chevron-circle-down fa-lg mt-4"></i><br>chevron-circle-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chevron-circle-left fa-lg mt-4"></i><br>chevron-circle-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chevron-circle-right fa-lg mt-4"></i><br>chevron-circle-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chevron-circle-up fa-lg mt-4"></i><br>chevron-circle-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chevron-down fa-lg mt-4"></i><br>chevron-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chevron-left fa-lg mt-4"></i><br>chevron-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chevron-right fa-lg mt-4"></i><br>chevron-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chevron-up fa-lg mt-4"></i><br>chevron-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-exchange fa-lg mt-4"></i><br>exchange
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-o-down fa-lg mt-4"></i><br>hand-o-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-o-left fa-lg mt-4"></i><br>hand-o-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-o-right fa-lg mt-4"></i><br>hand-o-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hand-o-up fa-lg mt-4"></i><br>hand-o-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-long-arrow-down fa-lg mt-4"></i><br>long-arrow-down
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-long-arrow-left fa-lg mt-4"></i><br>long-arrow-left
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-long-arrow-right fa-lg mt-4"></i><br>long-arrow-right
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-long-arrow-up fa-lg mt-4"></i><br>long-arrow-up
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-toggle-down fa-lg mt-4"></i><br>toggle-down <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-toggle-left fa-lg mt-4"></i><br>toggle-left <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-toggle-right fa-lg mt-4"></i><br>toggle-right <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-toggle-up fa-lg mt-4"></i><br>toggle-up <span class="text-muted">(alias)</span>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="video-player">
      <div slot="header">Video Player Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-arrows-alt fa-lg mt-4"></i><br>arrows-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-backward fa-lg mt-4"></i><br>backward
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-compress fa-lg mt-4"></i><br>compress
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-eject fa-lg mt-4"></i><br>eject
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-expand fa-lg mt-4"></i><br>expand
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-fast-backward fa-lg mt-4"></i><br>fast-backward
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-fast-forward fa-lg mt-4"></i><br>fast-forward
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-forward fa-lg mt-4"></i><br>forward
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pause fa-lg mt-4"></i><br>pause
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pause-circle fa-lg mt-4"></i><br>pause-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pause-circle-o fa-lg mt-4"></i><br>pause-circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-play fa-lg mt-4"></i><br>play
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-play-circle fa-lg mt-4"></i><br>play-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-play-circle-o fa-lg mt-4"></i><br>play-circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-random fa-lg mt-4"></i><br>random
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-step-backward fa-lg mt-4"></i><br>step-backward
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-step-forward fa-lg mt-4"></i><br>step-forward
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-stop fa-lg mt-4"></i><br>stop
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-stop-circle fa-lg mt-4"></i><br>stop-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-stop-circle-o fa-lg mt-4"></i><br>stop-circle-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-youtube-play fa-lg mt-4"></i><br>youtube-play
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body id="brand">
      <div slot="header">Brand Icons</div>
      <b-card-body>
        <div class="alert alert-warning">
          <h4><i class="fa fa-warning"></i> Warning!</h4>
          Apparently, Adblock Plus can remove Font Awesome brand icons with their "Remove Social
          Media Buttons" setting. We will not use hacks to force them to display. Please
          <a href="https://adblockplus.org/en/bugs" class="alert-link">report an issue with Adblock Plus</a> if you believe this to be
          an error. To work around this, you'll need to modify the social icon class names.
        </div>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-500px fa-lg mt-4"></i><br>500px
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-adn fa-lg mt-4"></i><br>adn
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-amazon fa-lg mt-4"></i><br>amazon
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-android fa-lg mt-4"></i><br>android
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-angellist fa-lg mt-4"></i><br>angellist
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-apple fa-lg mt-4"></i><br>apple
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-behance fa-lg mt-4"></i><br>behance
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-behance-square fa-lg mt-4"></i><br>behance-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bitbucket fa-lg mt-4"></i><br>bitbucket
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bitbucket-square fa-lg mt-4"></i><br>bitbucket-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bitcoin fa-lg mt-4"></i><br>bitcoin <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-black-tie fa-lg mt-4"></i><br>black-tie
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bluetooth fa-lg mt-4"></i><br>bluetooth
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-bluetooth-b fa-lg mt-4"></i><br>bluetooth-b
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-btc fa-lg mt-4"></i><br>btc
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-buysellads fa-lg mt-4"></i><br>buysellads
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-amex fa-lg mt-4"></i><br>cc-amex
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-diners-club fa-lg mt-4"></i><br>cc-diners-club
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-discover fa-lg mt-4"></i><br>cc-discover
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-jcb fa-lg mt-4"></i><br>cc-jcb
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-mastercard fa-lg mt-4"></i><br>cc-mastercard
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-paypal fa-lg mt-4"></i><br>cc-paypal
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-stripe fa-lg mt-4"></i><br>cc-stripe
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-cc-visa fa-lg mt-4"></i><br>cc-visa
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-chrome fa-lg mt-4"></i><br>chrome
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-codepen fa-lg mt-4"></i><br>codepen
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-codiepie fa-lg mt-4"></i><br>codiepie
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-connectdevelop fa-lg mt-4"></i><br>connectdevelop
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-contao fa-lg mt-4"></i><br>contao
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-css3 fa-lg mt-4"></i><br>css3
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-dashcube fa-lg mt-4"></i><br>dashcube
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-delicious fa-lg mt-4"></i><br>delicious
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-deviantart fa-lg mt-4"></i><br>deviantart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-digg fa-lg mt-4"></i><br>digg
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-dribbble fa-lg mt-4"></i><br>dribbble
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-dropbox fa-lg mt-4"></i><br>dropbox
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-drupal fa-lg mt-4"></i><br>drupal
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-edge fa-lg mt-4"></i><br>edge
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-empire fa-lg mt-4"></i><br>empire
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-expeditedssl fa-lg mt-4"></i><br>expeditedssl
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-facebook fa-lg mt-4"></i><br>facebook
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-facebook-f fa-lg mt-4"></i><br>facebook-f <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-facebook-official fa-lg mt-4"></i><br>facebook-official
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-facebook-square fa-lg mt-4"></i><br>facebook-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-firefox fa-lg mt-4"></i><br>firefox
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-flickr fa-lg mt-4"></i><br>flickr
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-fonticons fa-lg mt-4"></i><br>fonticons
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-fort-awesome fa-lg mt-4"></i><br>fort-awesome
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-forumbee fa-lg mt-4"></i><br>forumbee
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-foursquare fa-lg mt-4"></i><br>foursquare
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ge fa-lg mt-4"></i><br>ge <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-get-pocket fa-lg mt-4"></i><br>get-pocket
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gg fa-lg mt-4"></i><br>gg
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gg-circle fa-lg mt-4"></i><br>gg-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-git fa-lg mt-4"></i><br>git
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-git-square fa-lg mt-4"></i><br>git-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-github fa-lg mt-4"></i><br>github
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-github-alt fa-lg mt-4"></i><br>github-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-github-square fa-lg mt-4"></i><br>github-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gittip fa-lg mt-4"></i><br>gittip <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-google fa-lg mt-4"></i><br>google
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-google-plus fa-lg mt-4"></i><br>google-plus
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-google-plus-square fa-lg mt-4"></i><br>google-plus-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-google-wallet fa-lg mt-4"></i><br>google-wallet
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-gratipay fa-lg mt-4"></i><br>gratipay
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hacker-news fa-lg mt-4"></i><br>hacker-news
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-houzz fa-lg mt-4"></i><br>houzz
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-html5 fa-lg mt-4"></i><br>html5
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-instagram fa-lg mt-4"></i><br>instagram
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-internet-explorer fa-lg mt-4"></i><br>internet-explorer
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ioxhost fa-lg mt-4"></i><br>ioxhost
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-joomla fa-lg mt-4"></i><br>joomla
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-jsfiddle fa-lg mt-4"></i><br>jsfiddle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-lastfm fa-lg mt-4"></i><br>lastfm
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-lastfm-square fa-lg mt-4"></i><br>lastfm-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-leanpub fa-lg mt-4"></i><br>leanpub
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-linkedin fa-lg mt-4"></i><br>linkedin
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-linkedin-square fa-lg mt-4"></i><br>linkedin-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-linux fa-lg mt-4"></i><br>linux
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-maxcdn fa-lg mt-4"></i><br>maxcdn
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-meanpath fa-lg mt-4"></i><br>meanpath
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-medium fa-lg mt-4"></i><br>medium
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-mixcloud fa-lg mt-4"></i><br>mixcloud
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-modx fa-lg mt-4"></i><br>modx
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-odnoklassniki fa-lg mt-4"></i><br>odnoklassniki
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-odnoklassniki-square fa-lg mt-4"></i><br>odnoklassniki-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-opencart fa-lg mt-4"></i><br>opencart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-openid fa-lg mt-4"></i><br>openid
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-opera fa-lg mt-4"></i><br>opera
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-optin-monster fa-lg mt-4"></i><br>optin-monster
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pagelines fa-lg mt-4"></i><br>pagelines
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-paypal fa-lg mt-4"></i><br>paypal
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pied-piper fa-lg mt-4"></i><br>pied-piper
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pied-piper-alt fa-lg mt-4"></i><br>pied-piper-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pinterest fa-lg mt-4"></i><br>pinterest
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pinterest-p fa-lg mt-4"></i><br>pinterest-p
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-pinterest-square fa-lg mt-4"></i><br>pinterest-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-product-hunt fa-lg mt-4"></i><br>product-hunt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-qq fa-lg mt-4"></i><br>qq
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ra fa-lg mt-4"></i><br>ra <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-rebel fa-lg mt-4"></i><br>rebel
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-reddit fa-lg mt-4"></i><br>reddit
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-reddit-alien fa-lg mt-4"></i><br>reddit-alien
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-reddit-square fa-lg mt-4"></i><br>reddit-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-renren fa-lg mt-4"></i><br>renren
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-safari fa-lg mt-4"></i><br>safari
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-scribd fa-lg mt-4"></i><br>scribd
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-sellsy fa-lg mt-4"></i><br>sellsy
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-share-alt fa-lg mt-4"></i><br>share-alt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-share-alt-square fa-lg mt-4"></i><br>share-alt-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-shirtsinbulk fa-lg mt-4"></i><br>shirtsinbulk
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-simplybuilt fa-lg mt-4"></i><br>simplybuilt
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-skyatlas fa-lg mt-4"></i><br>skyatlas
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-skype fa-lg mt-4"></i><br>skype
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-slack fa-lg mt-4"></i><br>slack
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-slideshare fa-lg mt-4"></i><br>slideshare
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-soundcloud fa-lg mt-4"></i><br>soundcloud
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-spotify fa-lg mt-4"></i><br>spotify
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-stack-exchange fa-lg mt-4"></i><br>stack-exchange
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-stack-overflow fa-lg mt-4"></i><br>stack-overflow
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-steam fa-lg mt-4"></i><br>steam
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-steam-square fa-lg mt-4"></i><br>steam-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-stumbleupon fa-lg mt-4"></i><br>stumbleupon
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-stumbleupon-circle fa-lg mt-4"></i><br>stumbleupon-circle
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tencent-weibo fa-lg mt-4"></i><br>tencent-weibo
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-trello fa-lg mt-4"></i><br>trello
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tripadvisor fa-lg mt-4"></i><br>tripadvisor
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tumblr fa-lg mt-4"></i><br>tumblr
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-tumblr-square fa-lg mt-4"></i><br>tumblr-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-twitch fa-lg mt-4"></i><br>twitch
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-twitter fa-lg mt-4"></i><br>twitter
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-twitter-square fa-lg mt-4"></i><br>twitter-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-usb fa-lg mt-4"></i><br>usb
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-viacoin fa-lg mt-4"></i><br>viacoin
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-vimeo fa-lg mt-4"></i><br>vimeo
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-vimeo-square fa-lg mt-4"></i><br>vimeo-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-vine fa-lg mt-4"></i><br>vine
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-vk fa-lg mt-4"></i><br>vk
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-wechat fa-lg mt-4"></i><br>wechat <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-weibo fa-lg mt-4"></i><br>weibo
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-weixin fa-lg mt-4"></i><br>weixin
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-whatsapp fa-lg mt-4"></i><br>whatsapp
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-wikipedia-w fa-lg mt-4"></i><br>wikipedia-w
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-windows fa-lg mt-4"></i><br>windows
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-wordpress fa-lg mt-4"></i><br>wordpress
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-xing fa-lg mt-4"></i><br>xing
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-xing-square fa-lg mt-4"></i><br>xing-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-y-combinator fa-lg mt-4"></i><br>y-combinator
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-y-combinator-square fa-lg mt-4"></i><br>y-combinator-square <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-yahoo fa-lg mt-4"></i><br>yahoo
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-yc fa-lg mt-4"></i><br>yc <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-yc-square fa-lg mt-4"></i><br>yc-square <span class="text-muted">(alias)</span>
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-yelp fa-lg mt-4"></i><br>yelp
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-youtube fa-lg mt-4"></i><br>youtube
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-youtube-play fa-lg mt-4"></i><br>youtube-play
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-youtube-square fa-lg mt-4"></i><br>youtube-square
          </b-col>
        </b-row>
        <div class="alert alert-success mt-4">
          <ul class="margin-bottom-none padding-left-lg">
            <li>All brand icons are trademarks of their respective owners.</li>
            <li>The use of these trademarks does not indicate endorsement of the trademark holder by Font Awesome, nor vice versa.</li>
            <li>Brand icons should only be used to represent the company or product to which they refer.</li>
          </ul>
        </div>
      </b-card-body>
    </b-card>

    <b-card no-body id="medical">
      <div slot="header">Medical Icons</div>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-ambulance fa-lg mt-4"></i><br>ambulance
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-h-square fa-lg mt-4"></i><br>h-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-heart fa-lg mt-4"></i><br>heart
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-heart-o fa-lg mt-4"></i><br>heart-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-heartbeat fa-lg mt-4"></i><br>heartbeat
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-hospital-o fa-lg mt-4"></i><br>hospital-o
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-medkit fa-lg mt-4"></i><br>medkit
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-plus-square fa-lg mt-4"></i><br>plus-square
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-stethoscope fa-lg mt-4"></i><br>stethoscope
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-user-md fa-lg mt-4"></i><br>user-md
          </b-col>
          <b-col cols="6" sm="4" md="3" lg="2">
            <i class="fa fa-wheelchair fa-lg mt-4"></i><br>wheelchair
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'font-awesome'
}
</script>
